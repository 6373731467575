<template>
  <div class="main">
    <div class="wrap">
      <div>404</div>
      <p>页面错误，未找到该页面！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style lang="scss" scoped>
.main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap{
  padding: 80px 0;
  text-align: center;
  >div{
    font-weight: bold;
    font-size: 120px;
    color: #ff0000;
  }
  >p{
    line-height: 60px;
    font-size: 30px;
    color: $themeColorActive;
  }
}
</style>
